@import "./fontStack.css";

/* Madefor */
@supports (font-variation-settings: normal) {
  @font-face {
    font-family: Madefor;
    font-display: swap;
    font-weight: 400 800;
    font-style: normal;
    src: url(fonts/madefor/WixMadeforTextVF_W_Wght.woff2)
      format("woff2-variations");
  }

  @font-face {
    font-family: Madefor;
    font-display: swap;
    font-weight: 400 800;
    font-style: italic;
    src: url(fonts/madefor/WixMadeforTextItalicVF_W_Wght.woff2)
      format("woff2-variations");
  }
}

/* Madefor - legacy browsers */
@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: Madefor;
    font-display: swap;
    font-weight: 400;
    src: url(fonts/madefor/WixMadeforText_W_Rg.woff2) format("woff2"),
    url(fonts/madefor/WixMadeforText_W_Rg.woff) format("woff");
  }

  @font-face {
    font-family: Madefor;
    font-display: swap;
    font-weight: 400;
    font-style: italic;
    src: url(fonts/madefor/WixMadeforText_W_Rg_It.woff2) format("woff2"),
    url(fonts/madefor/WixMadeforText_W_Rg_It.woff) format("woff");
  }

  @font-face {
    font-family: Madefor;
    font-display: swap;
    font-weight: 700;
    src: url(fonts/madefor/WixMadeforText_W_Bd.woff2) format("woff2"),
    url(fonts/madefor/WixMadeforText_W_Bd.woff) format("woff");
  }

  @font-face {
    font-family: Madefor;
    font-display: swap;
    font-weight: 700;
    font-style: italic;
    src: url(fonts/madefor/WixMadeforText_W_Bd_It.woff2) format("woff2"),
    url(fonts/madefor/WixMadeforText_W_Bd_It.woff) format("woff");
  }

  @font-face {
    font-family: Madefor;
    font-display: swap;
    font-weight: 800;
    src: url(fonts/madefor/WixMadeforText_W_XBd.woff2) format("woff2"),
    url(fonts/madefor/WixMadeforText_W_XBd.woff) format("woff");
  }

  @font-face {
    font-family: Madefor;
    font-display: swap;
    font-weight: 800;
    font-style: italic;
    src: url(fonts/madefor/WixMadeforText_W_XBd_It.woff2) format("woff2"),
    url(fonts/madefor/WixMadeforText_W_XBd_It.woff) format("woff");
  }
}
